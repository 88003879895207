
import api from "@/api";
import { BrandModel } from "@/api/generated";
import environment from "@/environment";
import SnackbarModule from "@/store/snackbarModule";
import nameof from "@/utility/nameof";
import { Vue, Component } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class BrandTable extends Vue {
  private loading = false;
  private showArchived = false;
  private items: Array<BrandModel> = [];
  private headers: Array<DataTableHeader<BrandModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<BrandModel>("name") },
    { text: "ABV", value: nameof<BrandModel>("abv") },
    { text: "Logo", value: nameof<BrandModel>("logoBlobStorageId") },
    { text: "Primary Colour", value: nameof<BrandModel>("primaryColour") },
    { text: "Secondary Colour", value: nameof<BrandModel>("secondaryColour") },
  ];

  private async created() {
    await this.getBrands();
  }

  private async getBrands() {
    try {
      this.loading = true;
      const response = await api.BrandService.apiBrandGet();
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retreive brands");
    } finally {
      this.loading = false;
    }
  }

  private getLogoLink(blobId: string) {
    return `${environment.API_URL}/api/brand/logo/${blobId}`;
  }

  get filteredBrands(): Array<BrandModel> {
    if (this.items.length > 0) {
      return this.items.filter((x) => x.archived == this.showArchived);
    }
    return [];
  }
}
