import { render, staticRenderFns } from "./BrandTable.vue?vue&type=template&id=409c096d&scoped=true"
import script from "./BrandTable.vue?vue&type=script&lang=ts"
export * from "./BrandTable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "409c096d",
  null
  
)

export default component.exports