
import api from "@/api";
import { BrandModel } from "@/api/generated";
import SnackbarModule from "@/store/snackbarModule";
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import BrandTable from "@/components/brand/BrandTable.vue";
import BrandCreateDialog from "@/components/dialogs/brand/BrandCreateDialog.vue";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { BrandTable, BrandCreateDialog } })
export default class BrandOverview extends Vue {
  private tableRefreshKey = false;
  private createDialog = false;
  private deleteDialog = false;
  private selectedBrandId = "";

  private async onDeleteRequest(brand: BrandModel) {
    if (!brand.brandId) {
      return;
    }
    this.selectedBrandId = brand.brandId;
    this.deleteDialog = true;
  }

  private async onCreateBrand() {
    this.createDialog = true;
  }

  private onUpdateBrand(item: BrandModel) {
    if (!item.brandId) {
      return;
    }

    this.$router.push({
      name: "Brand Edit",
      params: { brandId: item.brandId },
    });
  }

  private async archiveBrand(item: BrandModel) {
    if (!item.brandId) {
      return;
    }

    this.selectedBrandId = item.brandId;

    try {
      await api.BrandService.apiBrandArchiveBrandIdDelete(this.selectedBrandId);
      snackbarModule.setSnackbarMessage("Brand successfully archived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to archive brand");
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }

  private async unarchiveBrand(item: BrandModel) {
    if (!item.brandId) {
      return;
    }

    this.selectedBrandId = item.brandId;

    try {
      await api.BrandService.apiBrandUnarchiveBrandIdDelete(
        this.selectedBrandId
      );
      snackbarModule.setSnackbarMessage("Brand successfully unarchived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to unarchive brand");
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }

  private async onDeleteBrand(item: BrandModel) {
    if (!this.selectedBrandId) {
      return;
    }

    try {
      await api.BrandService.apiBrandBrandIdDelete(this.selectedBrandId);
      snackbarModule.setSnackbarMessage("Brand successfully deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete brand");
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }
}
